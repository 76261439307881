import * as React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import './styles.scss'

const InsurerLogoStrip = loadable(() => import('/src/components/PageBuilder/layouts/global/InsurerLogoStrip'))
const LeadGenJourney = loadable(() => import('/src/forms/LeadGen/LeadGenJourney'))
const RafJourney = loadable(() => import('/src/forms/LeadGen/RafJourney'))
const Section = loadable(() => import('/src/components/Structure/Section/Section'))

const JourneyBlock = ({ backgroundColour, titleArea, formSelect }) => {
    const journeys = {
        LeadGenJourney,
        RafJourney
    }

    const Journey = journeys[formSelect]

    return (
        <div id="form" className="c-journey-block">
            <InsurerLogoStrip className={'c-journey-block__insurer-strip'} />

            <Section variant={backgroundColour} size={'sm'} titleArea={titleArea}>
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <Journey />
                    </div>
                </div>
            </Section>
        </div>
    )
}

JourneyBlock.propTypes = {
    backgroundColour: PropTypes.oneOf(['light', 'alt', 'dark', 'gradient']).isRequired,
    titleArea: PropTypes.shape({
        addTitleArea: PropTypes.bool,
        title: PropTypes.string,
        description: PropTypes.string
    }),
    formSelect: PropTypes.oneOf(['LeadGenJourney', 'RafJourney']).isRequired
}

JourneyBlock.defaultProps = {
    backgroundColour: 'light',
    titleArea: {
        addTitleArea: true,
        title: 'Section Title'
    },
    formSelect: 'LeadGenJourney'
}

export default JourneyBlock

export const query = graphql`
    fragment JourneyBlockForPageBuilder on WpPage_Acfpagebuilder_Layouts_JourneyBlock {
        fieldGroupName
        backgroundColour
        addTitleArea
        title
        description
        formSelect
    }
`
